<template>
  <movable-area style="height: 100%; width: 100%;pointer-events: none;" class="serverBox"  >
    <movable-view style="height: 100px; width: 50px;pointer-events: auto;" v-if="movShow" @change="bindchange"  :x="`${leftX}rpx`" :y="`${topY}rpx`" direction="all" >
      <a href="tel:4007781600" ref="telPhone" class="phone">
        <span class="telPhone">
          <!-- 中秋 -->
          <img class="serviceIcon" ref="serviceIcon" src="./hotline_cj.png" />
        </span>
      </a>
      <view class="kefu" @click="toCustomerService">
        <span v-if="infoTotal" class="infoMsg">{{ infoTotal }}</span>
        <img class="serviceIcon" ref="serviceIcon" src="./online_cj.png" />
      </view>
    </movable-view>
  </movable-area>
</template>
<script>
import {  onShow } from '@dcloudio/uni-app';
export default {
  name: 'cusService',
  props: {},
  data() {
    return {
      oldMousePos: {}, // 手指原始位置
      oldNodePos: {}, // 元素原始位置
      infoTotal: 0,
      offsetL: `calc(100% - 60px)`,
      offsetR: `calc(100% - 200px)`,
      userId: uni.getStorageSync('userId') || '',
      userInfo: uni.getStorageSync('user-store')
        ? JSON.parse(uni.getStorageSync('user-store'))
        : {},
      activityId:'',  
      Source:'', 
      templateId: 6669746 ,
      leftX: 20 ,
      topY: 1000,
      movShow:false
    };
  },
  computed: {},

  created() {
    this.activityId = uni.getStorageSync('activityId') || ''
    this.Source = uni.getStorageSync("Source")||''
    this.init()
  },
  // activated(){
  //   this.init()
  // },
  mounted() {
    //  获取未读消息
    if (!this.userId) return;
    const that = this;
    if(!ysf) return
    setTimeout(() => {
      ysf('onunread', function (result) {
        that.infoTotal = result.total || 0;
        if (that.infoTotal > 9) that.infoTotal = 9;
      });
    }, 2000);
  },
  methods: {
    // 初始化位置
    init(){
      this.movShow = false
      const X = sessionStorage.getItem('serverX') || ''
      const Y = sessionStorage.getItem('serverY') || ''
      if( X && Y) {
        this.leftX = X * 2
        this.topY = Y * 2
      }  
      setTimeout(()=>{
        this.movShow = true
      },50)
    },
    /* 在线客服 */
    toCustomerService() {
      if(!ysf) return
      if(this.Source === 'GK'){
         this.templateId = 6672728
      }  
      if(!this.userId){
        this.userId = this.userInfo.userInfo.mobile
      }
      const that = this   
      ysf('config', {
        uid: this.userId || '',
        name: this.userInfo.userInfo?this.userInfo.userInfo.nickname : '',
        email: '',
        mobile: this.userInfo.userInfo?this.userInfo.userInfo.mobile : '',
        qtype: 6672728, // 常见问题模板
        success: function () {
          ysf('open',{templateId:that.templateId});
        },
      });
    },
    //  获取拖拽客服位置
    bindchange(e){
      if(e.detail.source == 'touch'){
        sessionStorage.setItem('serverX',e.detail.x)
        sessionStorage.setItem('serverY',e.detail.y)
      }
    },
  
    /* 拖拽客服开始 */
    touchstart(ev) {
      const selectDom = ev.currentTarget;
      // ev.preventDefault();
      const { pageX, pageY } = ev.touches[0]; // 手指位置
      const { offsetLeft, offsetTop } = selectDom; // 元素位置

      // 手指原始位置
      this.oldMousePos = {
        x: pageX,
        y: pageY,
      };

      // 元素原始位置
      this.oldNodePos = {
        x: offsetLeft,
        y: offsetTop,
      };

      this.offsetL = `${offsetLeft}px`;
      this.offsetR = `${offsetTop}px`;
    },

    /* 拖拽客服移动 */
    touchMove(ev) {
      const selectDom = ev.currentTarget;
      const lefts = this.oldMousePos.x - this.oldNodePos.x; // x轴偏移量
      const tops = this.oldMousePos.y - this.oldNodePos.y; // y轴偏移量
      const { pageX, pageY } = ev.touches[0]; // 手指位置

      this.offsetL = `${pageX - lefts}px`;
      this.offsetR = `${pageY - tops}px`;
    },

    /* 拖拽客服结束 */
    touchEnd(ev) {
      const selectDom = ev.currentTarget;
      const { clientWidth, clientHeight } = document.body;
      const { offsetLeft, offsetTop } = selectDom;
      if (offsetLeft < 50) {
        // 偏左
        this.offsetL = `0px`;
      }
      if (offsetLeft > clientWidth - 50) {
        // 偏右
        this.offsetL = `${clientWidth - 50}px`;
      }
      if (offsetTop < 50) {
        // 偏上
        this.offsetR = `0px`;
      }
      if (offsetTop > clientHeight - 50) {
        // 偏下
        this.offsetR = `${clientHeight - 50}px`;
      }
    },
  },
};
</script>

<style lang="scss">
.serverBox {
  width: 100%;
  height: 100%;
  position: fixed;
  top:0;
  left: 0;
  z-index: 99;
  .phone {
    width: 46px;
    height: 46px;
    .telPhone {
      width: 46px;
      height: 46px;
      display: inline-block;
      
      // background: linear-gradient(180deg, #42b0ff 0%, #4073ff 70%);
      // box-shadow: inset 0px 4px 4px 0px rgba(90, 186, 255, 0.4),
      // inset 0px -4px 4px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(128, 161, 255, 0.4);
      // 春节
      background: linear-gradient(180deg, #fd5351 0%, #e13736 70%);
      box-shadow: inset 0px 4px 4px 0px rgba(255, 203, 90, 0.4),
      inset 0px -4px 4px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(253, 255, 128, 0.4);

      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      width: 42px;
    }
  }
  .kefu {
    margin-top: 5px;
    position: relative;
    width: 46px;
    height: 46px;
    // background: linear-gradient(180deg, #42b0ff 0%, #4073ff 70%);
    // box-shadow: inset 0px 4px 4px 0px rgba(90, 186, 255, 0.4),
    //   inset 0px -4px 4px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(128, 161, 255, 0.4);
    // 春节
    background: linear-gradient(180deg, #fd5351  0%, #e13736 70%);
    box-shadow: inset 0px 4px 4px 0px rgba(255, 203, 90, 0.4),
    inset 0px -4px 4px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(253, 255, 128, 0.4);

    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 42px;
    }
    .infoMsg {
      position: absolute;
      top: 0;
      right: 5px;
      background: #f00;
      color: #fff;
      font-size: 10px;
      width: 16px;
      height: 16px;
      line-height: 16px;
      border-radius: 50%;
      text-align: center;
    }
  }
}

</style>
