<template>
  <view class="price">
    <view v-if="price || price===0" class="moneyLeft" :style="`color:${moneyColor};font-size:${fontSize}px`">
      <image v-if="priceImg"
        src="https://h5.fushangyunfu.com/public/yunfuHelp/beanIcon.png"  :style="`width:${ImgWidth}px;height:${ImgWidth}px;`" />
      <view v-if="price" class="money" :style="`font-size:${fontSize}px; line-height:${ImgWidth}px;`"> {{ fen2yuan(price) }} </view>
      <view class="money" v-else :style="`line-height:${ImgWidth}px;`"> 0 </view>
      <view v-if="range" class="info"> 起</view>
    </view>
  </view>
</template>

<script>
import { fen2yuan } from "@/sheep/hooks/useGoods";
export default {
  name: "price",
  props: {
    priceImg: {
      type: Boolean,
      default: true
    },
    price: {
      type: [String, Number],
      default: ""
    },
    moneyColor: {
      type: String,
      default: '#fb5436'
    },
    fontSize: {
      type: [String, Number],
      default: '16'
    },
    ImgWidth: {
      type: [String, Number],
      default: '16'
    },
    range: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    fen2yuan
  }
};
</script>

<style lang="scss" scoped>
.price {
  display: flex;
  align-items: center;
  .moneyLeft {
    display: flex;
    align-items: center;
    font-size: 36rpx;
    font-weight: 600;
    image {
      margin-right: 8rpx;
      position: relative;
      top: -2rpx;
    }
    .money {
      font-size: 32rpx;
      text-align: center;
      line-height: 1;
      margin-top: 2rpx;
    }
    .info {
      font-size: 12px;
      margin-left: 6rpx;
    }
  }
}
</style>
