import request from "@/sheep/request";

const cakesApi = {

    // 获取bannar 列表
    getBannerList(data) {
        return request({
            url: 'promotion/banner/list',
            method: 'GET',
            data
        })
    },
    // 获取栏目列表
    getTypeList(data) {
        return request({
            url: 'product/type/getTypeList',
            method: 'GET',
            data: data
        })
    },
    // 根据id获取品牌详情
    getBrandById(data) {
        return request({
            url: 'product/brand/getBrandById',
            method: 'GET',
            data: data
        })
    },
    // 获取品牌列表
    getBrandList(data) {
        return request({
            url: 'product/brand/getBrandList',
            method: 'GET',
            data: data,
        })
    },
    // 获取热门品牌列表
    getBrandListNew(data) {
        return request({
            url: 'product/brand/getBrandHot',
            method: 'GET',
            data: data,
        })
    },
    // 根据栏目查商品列表
    getSpuList(data) {
        return request({
            url: 'product/spu/page-by-type',
            method: 'GET',
            data: data
        })
    },
    // 根据专区获得商品 SPU 分页
    getSpuListByZone(data) {
        return request({
            url: "product/spu/page-by-activityId",
            method: "GET",
            data: data,
        });
    },

    // 获取首页信息
    getCakesMessage() {
        return request({
            url: 'promotion/diy-page/get',
            method: 'GET',
            data: {
                id: 10
            },
        })
    },

    // 获取第三方渠道配置 
    getThirdSetting(id) {
        return request({
            url: '/third/channel-config/get',
            method: 'GET',
        })
    },


    // // 获取城市列表
    // getCityList(){
    //     return request({
    //         url: 'movie/city/list',
    //         method: 'GET',
    //     })
    // }
}

export default cakesApi;